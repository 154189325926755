import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
// import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const IndexPage = ({ data }) => {
  
  return (
    <Layout>
      <SiteMetadata title="Home" description="Portfolio of John Doe" />


      {/* <Hero title="La Maison de l'Evénementiel" subtitle="Catalogue 2021" /> */}

      {/* <Hero /> */}
      {/* <section class="hero"> */}
  {/* <img src="./fond_home.jpg" alt="houses on the water" class="w-full" /> */}
{/* </section> */}
      
      <div className="bg-gray-100 py-12 lg:py-16">
        {data.articles && data.articles.nodes.length > 0 ? (
          <Cards items={data.articles.nodes} />
        ) : (
          <div className="container">No projects found.</div>
        )}
      </div>
      <Newsletter />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    articles: allContentfulArticles (limit: 15) {
      nodes {
        ...ArticlesCard
      }
    }
  }
`
